<template>
  <b-modal
    id="get-template"
    size="lg"
    centered
    :title="$t('empty-layout.template-button')"
    hide-footer
    no-close-on-backdrop
    @show="fetchData"
    @hidden="closeModal"
  >
    <b-form class="mt-2" @submit.prevent="handleCreateItem">
      <b-card v-if="alert" class="border border-danger text-danger text-center">
        <p class="text-danger">
          <feather-icon icon="AlertTriangleIcon" size="16" /> {{ alert }}
        </p>
      </b-card>
      <b-form-group :label="$t('empty-layout.template-label')" label-for="template">
        <v-select
          id="template"
          v-model="layoutTemplate"
          :options="layoutTemplates"
          :reduce="(template) => template.key"
          label="name"
          :clearable="false"
          :placeholder="$t('empty-layout.template-placeholder')"
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="float-right mt-2 mb-1"
        :disabled="layoutTemplates.length===0||isSending"
        type="submit"
      >
        <span
          v-if="isSending"
          class="d-flex "
        >
          <b-spinner
            class="mr-1"
            small
            variant="white"
            label="Loading..."
          />
          {{ $t('form.actions.save') }}
        </span>
        <span v-else>{{ $t('empty-layout.load-template') }}</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

export default {
  name: 'LayoutTemplateGetModal',
  mixins: [ToastNotificationsMixin],
  props: { alert: null },
  components:{ vSelect },
  data() {
    return {
      isSending: false,
      isLoading: false,
      templates: [],
      layoutTemplate: 0,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    communitySlug() {
      return this.$store.getters.currentCollective.slug;
    },
    itemsData() {
      return this.$store.getters.layoutTemplates;
    },
    layoutTemplates() {
      if (this.itemsData?.unpaginated.length > 0) {
        return [...this.templates, ...this.itemsData.unpaginated];
      }
      return this.templates;
    },
  },

  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    async fetchData() {
      if (this.currentCollective?.isStaff) {
        await this.$store.dispatch('getItems', {
          itemType: 'layout/templates',
          page: 1,
          perPage: 30,
          customName: 'layoutTemplates',
          communitySlug: this.communitySlug,
        });
      }
    },
    async handleCreateItem() {
      this.isSending = true;
      try {
        await this.$store.dispatch("createItem", {
          noSet: true,
          item: {
            itemType: 'layout/templates/apply',
            requestConfig: {
              templateKey: this.layoutTemplate,
              mainType: this.currentCollective.mainType,
            },
          },
        });
        this.closeModal();
        this.isSending = false;
        location.reload();
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
        this.isSending = false;
      }
    },
    closeModal() {
      this.$bvModal.hide('get-template');
    },
  },
};
</script>
