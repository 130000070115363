<template>
  <b-modal
    id="layout-template-modal"
    size="lg"
    centered
    :title="$t('header.action.add-layout-template')"
    hide-footer
    @hidden="closeModal"
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form class="mt-2" @submit.prevent="handleSubmit(handleCreateItem)">
        <validation-provider #default="validationContext" name=" " rules="required">
          <b-form-group :label="$t('header.action.layout-template-title')" label-for="title">
            <b-form-input
              id="title"
              v-model="name"
              rules="required"
              :state="getValidationState(validationContext)"
              :placeholder="$t('form.type-placeholder')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-button
          variant="primary"
          class="float-right mt-2"
          :disabled="isSending"
          type="submit"
        >
          <span
            v-if="isSending"
            class="d-flex "
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t('form.actions.save') }}
          </span>
          <span v-else>{{ $t('form.actions.save') }}</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'LayoutTemplateCreateModal',
  components: { ValidationProvider, ValidationObserver },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isSending: false,
      required,
      name: '',
    };
  },
  computed: {
    savedLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.savedLayout];
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  methods: {
    async handleCreateItem() {
      this.isSending = true;
      try {
        await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: '/layout/templates',
            requestConfig: {
              name: this.name,
              layout: this.savedLayout,
            },
          },
        });
        this.notifySuccess(this.$t('layouts.message.saved-success'));
        this.closeModal();
        this.isSending = false;
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
        this.isSending = false;
      }
    },
    closeModal() {
      this.name = '';
      this.$bvModal.hide('layout-template-modal');
    },
  },
};
</script>

<style></style>
